<template>
  <div class="zone">
    <TJDetailTitle :titleData="'基本信息'" />

    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'日志图片'" />
    <div class="imgBox">
      <el-image
        v-for="(item, index) in detailData.diary_image"
        :key="index"
        style="width: 300px; height: 300px"
        :src="item"
        :fit="fit"
      ></el-image>
    </div>
  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
export default {
  components: { TJDetailTitle, TJDetailMenu },
  name: 'DiarysDetail',

  data() {
    return {
      url: '',
      fit: '',
      detailId: '',
      detailData: {},
      diary_type: {},
      dataList: [
        {
          name: '记录人',
          value: '',
          width: '25%',
        },
        {
          name: '定位',
          value: '',
          width: '25%',
        },
        {
          name: '日志类型',
          value: '',
          width: '25%',
        },
        {
          name: '创建时间',
          value: '',
          width: '25%',
          rightBorder: false,
        },
        {
          name: '开始时间',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
        {
          name: '结束时间',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
        {
          name: '工作事由',
          value: '',
          width: '50%',
          bottomBorder: false,
        },
      ],
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.gitdiaryDetail()
  },
  methods: {
    gitdiaryDetail() {
      apiDetail.diaryDetail({ diary_id: this.detailId }).then((res) => {
        this.detailData = res.data
        this.dataList[0].value = this.detailData.diary_recorder
        this.dataList[1].value = this.detailData.location
        this.dataList[2].value = this.detailData.diary_type[0]
        this.dataList[3].value = this.detailData.create_time
        this.dataList[4].value = this.detailData.start_date
        this.dataList[5].value = this.detailData.end_date
        this.dataList[6].value = this.detailData.diary_content
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.imgBox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .el-image {
    margin: 0 px(20) px(10) 0;
  }
}
</style>
